import request from '../Axios'

// 封号
export const freeze = params => {
  return request({
    url: `/wechat/user/lock/${params.id}`,
    method: 'get'
  })
}

// 解封
export const unblock = params => {
  return request({
    url: `/wechat/user/unblock/${params.id}`,
    method: 'get'
  })
}

// 积分变更
export const integralHistory = data => {
  return request({
    url: '/wechat/user/integral/history',
    method: 'get',
    params: data
  })
}


// 积分变更
export const operationIntegral = data => {
  return request({
    url: '/wechat/user/operation/integral',
    method: 'post',
    data
  })
}

// 用户列表
export const guestPage = data => {
  return request({
    url: '/wechat/user/page',
    method: 'get',
    params: data
  })
}

// 获取活动字典列表(用户管理)
export const dictActive = () => {
  return request({
    url: '/common/dict/active',
    method: 'get',
  })
}

// 大类字典列表接口(用户管理)
export const dictClassify = () => {
  return request({
    url: '/common/dict/classify',
    method: 'get',
  })
}

// 导出记录
export const userExport = data => {
  return request({
    url: '/wechat/user/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 员工列表
export const employeePage = data => {
  return request({
    url: '/user/employee/page',
    method: 'post',
    data
  })
}

// 下载员工excel模板文件
export const employeeTemplate = () => {
  return request({
    url: '/user/employee/template',
    method: 'get',
    responseType: 'blob'
  })
}

// 导入
export const importTemplate = data => {
  return request({
    url: '/user/employee/import',
    method: 'POST',
    data
  })
}

// 设置用户类型
export const userSetAdmin = data => {
  return request({
    url: '/wechat/user/setAdmin',
    method: 'POST',
    data
  })
}

export const wechatUserList = (data) => {
  return request({
    url: `/wechat/user/list`,
    method: 'post',
    data
  })
}

<template>
  <div class="addTheme">
    <div class="top">
      <h6>预约活动-{{ themeTitle }}</h6>
      <div class="back" @click="cancel">返回上一级</div>
    </div>
    <div class="addTheme_main">
      <div v-if="themeTitle == '详情'" class="addTheme_main_mask"></div>
      <div class="writeoff_box">
        <span><i>*</i>关联活动类型：</span>
        <el-select
          v-model="addForm.typeId"
          class="dialog_input"
          placeholder="请选择"
        >
          <el-option
            v-for="item in parentList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>活动标题：</span>
        <el-input
          v-model="addForm.activityName"
          class="dialog_input"
          placeholder="请输入"
          type="text"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>副标题：</span>
        <el-input
          v-model="addForm.subtitle"
          class="dialog_input"
          placeholder="请输入"
          type="text"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>邀约类型：</span>
        <el-select
          v-model="addForm.invitationType"
          class="dialog_input"
          placeholder="请选择"
        >
          <el-option
            v-for="item in invitationTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>活动时间：</span>
        <el-date-picker
          v-model="dateTime"
          :disabled-date="disabledDate"
          end-placeholder="结束时间"
          format="YYYY-MM-DD"
          range-separator="至"
          start-placeholder="开始时间"
          type="daterange"
          value-format="YYYY-MM-DD"
          @change="getChangeTime"
        />
      </div>
      <div class="writeoff_box">
        <span><i>*</i>每天可预约人数：</span>
        <el-input
          v-model="addForm.limitedQuota"
          class="dialog_input"
          placeholder="请输入"
          type="text"
          @keyup="numberInput1"
        ></el-input>
      </div>
      <div class="writeoff_box">
        <span>总可预约人数：</span>
        <el-input
          v-model="addForm.limitedQuotaTotal"
          class="dialog_input"
          placeholder="请输入"
          type="text"
          @blur="handleBlur"
          @keyup="numberInput2"
        ></el-input>
      </div>
      <div class="writeoff_box remark_box">
        <span>活动介绍：</span>
        <!-- <el-input
          v-model="addForm.description"
          :rows="5"
          type="textarea"
          placeholder="请输入"
          class="dialog_input"
        ></el-input> -->
        <div class="details" style="position: relative">
          <QuillEditor
            ref="quillRef"
            v-model:content="summary"
            :options="editorOption"
            class="myQuillEditor"
            contentType="html"
            theme="snow"
            @update:content="onEditorChange($event)"
          />
          <div class="numtip">
            {{ TiLength }}/5000
          </div>
        </div>
      </div>
      <div class="writeoff_box remark_box">
        <span><i>*</i>活动封面图：</span>
        <el-upload
          v-loading="loadingDialog"
          :action="UploadUrl()"
          :before-upload="beforeUploadFile"
          :disabled="fileDisabled"
          :file-list="fileList"
          :http-request="uploadFile"
          :limit="1"
          :on-change="fileChange"
          :on-exceed="exceedFile"
          :on-remove="handleRemove"
          class="upload-demo"
          drag
        >
          <img v-if="fileObj.url" :src="fileObj.url" style="width: 100%"/>
          <el-icon v-if="!fileObj.url" class="el-icon--upload">
            <!-- <upload-filled /> -->
            <I name="UploadFilled"></I>
          </el-icon>
          <div v-if="!fileObj.url" class="el-upload__text">
            将文件拖拽此处，或<em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              建议尺寸 850*676
              (建议尺寸在小程序端展示效果最佳)且图片大小不超过5MB
            </div>
          </template>
        </el-upload>
      </div>
      <div class="writeoff_box icon_box">
        <span><i>*</i>排序：</span>
        <el-input
          v-model="addForm.sort"
          class="dialog_input"
          placeholder="请输入"
          type="text"
          @keyup="numberInput"
        ></el-input>
        <el-tooltip
          class="box-item"
          content="数字越大显示位置越靠前"
          effect="dark"
          placement="top"
        >
          <I class="tipIcon" name="QuestionFilled"></I>
        </el-tooltip>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>活动地址：</span>
        <span class="addhj" @click="addRessList">点击添加活动地址</span>
        <!-- <el-input
          v-model="addForm.offlineAddress"
          :rows="5"
          type="textarea"
          placeholder="请输入"
          class="dialog_input"
        ></el-input> -->
        <!-- <QuillEditor
          class="myQuillEditor"
          ref="quillRefAddress"
          theme="snow"
          v-model:content="addForm.offlineAddress"
          :options="addressOption"
          contentType="html"
        /> -->
      </div>
      <div class="writeoff_box jf_box addressbox">
        <span></span>
        <div>
          <template v-for="(item, i) in addressModelList" :key="i">
            <div class="cont">
              <span>活动地址{{ i + 1 }}：</span>
              <el-input
                v-model="item.offlineAddress"
                class="dialog_input"
                placeholder="请输入"
                type="text"
              ></el-input>
              <I
                class="delete"
                name="RemoveFilled"
                @click="deleteAddressModel(i)"
              ></I>
            </div>
            <div class="cont" style="margin: 10px 0 10px 20px">
              <span>预约时间段：</span>
              <span class="addhj" style="width: auto" @click="addDateList(i)">
                点击添加预约日期
              </span>
            </div>
            <div
              v-for="(item1, index) in item.reservationTimeModelList"
              :key="index"
              class="cont"
              style="margin: 10px 0 10px 40px; display: block"
            >
              <div style="display: flex; align-items: center">
                <span style="width: auto">预约日期：</span>
                <el-date-picker
                  v-model="item1.date"
                  :disabled-date="disabledDate1"
                  end-placeholder="结束日期"
                  format="YYYY-MM-DD"
                  range-separator="-"
                  start-placeholder="开始日期"
                  style="margin-right: 10px; width: 440px"
                  type="daterange"
                  value-format="YYYY-MM-DD"
                />
                <span
                  class="addhj"
                  style="width: auto"
                  @click="addTimeList(i, index)"
                >
                  点击添加预约时间段
                </span>
                <I
                  class="delete"
                  name="RemoveFilled"
                  @click="deleteDateList(i, index)"
                ></I>
              </div>
              <div style="margin: 10px 10px 0">
                <template
                  v-for="(item2, index2) in item1.timeModelList"
                  :key="index2"
                >
                  <div
                    v-if="index2 == 0"
                    class="cont"
                    style="margin-bottom: 5px"
                  >
                    <span>预约时间：</span>
                    <div class="demo-time-range">
                      <el-time-select
                        v-model="item2.start"
                        :max-time="item2.end"
                        class="mr-4"
                        end="23:59"
                        placeholder="开始时间"
                        start="00:00"
                        step="00:15"
                      />
                      <el-time-select
                        v-model="item2.end"
                        :min-time="item2.start"
                        end="23:59"
                        placeholder="结束时间"
                        start="00:00"
                        step="00:15"
                      />
                    </div>
                    <span>预约人数：</span>
                    <el-input
                      v-model="item2.peopleCount"
                      class="dialog_input"
                      placeholder="请输入"
                      type="text"
                    ></el-input>
                    <I
                      class="delete"
                      name="RemoveFilled"
                      @click="deleteTimeList(i, index, index2)"
                    ></I>
                  </div>
                  <div
                    v-if="index2 > 0"
                    class="cont"
                    style="margin-bottom: 5px"
                  >
                    <span>预约时间：</span>
                    <div class="demo-time-range">
                      <el-time-select
                        v-model="item2.start"
                        :max-time="item2.end"
                        :min-time="item1.timeModelList[index2 - 1].end"
                        class="mr-4"
                        end="23:59"
                        placeholder="开始时间"
                        start="00:00"
                        step="00:15"
                      />
                      <el-time-select
                        v-model="item2.end"
                        :min-time="item2.start"
                        end="23:59"
                        placeholder="结束时间"
                        start="00:00"
                        step="00:15"
                      />
                    </div>
                    <span>预约人数：</span>
                    <el-input
                      v-model="item2.peopleCount"
                      class="dialog_input"
                      placeholder="请输入"
                      type="text"
                    ></el-input>
                    <I
                      class="delete"
                      name="RemoveFilled"
                      @click="deleteTimeList(i, index, index2)"
                    ></I>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="writeoff_box">
        <span>其他预约时间段：</span>
        <span class="addhj" style="width: 130px" @click="addTime"
        >点击添加预约时间段</span
        >
      </div>
      <div class="writeoff_box jf_box">
        <span></span>
        <div>
          <template v-for="(item, i) in timeModelList" :key="i">
            <div v-if="i == 0" class="cont" style="margin-bottom: 5px">
              <span>预约时间：</span>
              <div class="demo-time-range">
                <el-time-select
                  v-model="item.start"
                  :max-time="item.end"
                  class="mr-4"
                  end="23:59"
                  placeholder="开始时间"
                  start="00:00"
                  step="00:15"
                />
                <el-time-select
                  v-model="item.end"
                  :min-time="item.start"
                  end="23:59"
                  placeholder="结束时间"
                  start="00:00"
                  step="00:15"
                />
              </div>
              <span>预约人数：</span>
              <el-input
                v-model="item.peopleCount"
                class="dialog_input"
                placeholder="请输入"
                type="text"
              ></el-input>
              <I class="delete" name="RemoveFilled" @click="deleteTime(i)"></I>
            </div>
            <div v-if="i > 0" class="cont" style="margin-bottom: 5px">
              <span>预约时间：</span>
              <div class="demo-time-range">
                <el-time-select
                  v-model="item.start"
                  :max-time="item.end"
                  :min-time="timeModelList[i - 1].end"
                  class="mr-4"
                  end="23:59"
                  placeholder="开始时间"
                  start="00:00"
                  step="00:15"
                />
                <el-time-select
                  v-model="item.end"
                  :min-time="item.start"
                  end="23:59"
                  placeholder="结束时间"
                  start="00:00"
                  step="00:15"
                />
              </div>
              <span>预约人数：</span>
              <el-input
                v-model="item.peopleCount"
                class="dialog_input"
                placeholder="请输入"
                type="text"
              ></el-input>
              <I class="delete" name="RemoveFilled" @click="deleteTime(i)"></I>
            </div>
          </template>
        </div>
      </div>
      <div class="writeoff_box">
        <span>活动席位：</span>
        <span class="addhj" @click="addSeats">点击添加活动席位</span>
      </div>
      <div class="writeoff_box jf_box">
        <span></span>
        <div>
          <template v-for="(item, i) in seats" :key="i">
            <div v-if="item.type != 'other'" class="cont">
              <span>席位名称：</span>
              <el-input
                v-model="item.seatName"
                :maxLength="6"
                class="dialog_input"
                placeholder="请输入"
                type="text"
              ></el-input>
              <el-button type="primary" @click="getUser(i)">
                选择关联用户
              </el-button>
              <I class="delete" name="RemoveFilled" @click="deleteSeats(i)"></I>
            </div>
            <el-table
              v-if="item.seatsList && item.seatsList.length > 0"
              :data="item.seatsList"
              border
              style="
                width: 100%;
                margin: 10px 0;
                max-height: 300px;
                overflow-y: auto;
              "
            >
              <el-table-column align="center" label="用户头像">
                <template #default="scoped">
                  <div class="desc">
                    <img
                      :src="
                        scoped.row.avatarUrl
                          ? scoped.row.avatarUrl
                          : require('../../../assets/img/logo.jpg')
                      "
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="昵称" prop="nickName">
              </el-table-column>
              <el-table-column align="center" label="手机号" prop="phone">
              </el-table-column>
              <el-table-column
                align="center"
                label="注册时间"
                prop="createTime"
              >
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
      <div class="writeoff_box">
        <span>活动积分环节：</span>
        <span class="addhj" @click="addJF">点击添加积分环节</span>
      </div>
      <div class="writeoff_box jf_box">
        <span></span>
        <div>
          <template v-for="(item, i) in stageModelList" :key="i">
            <div v-if="item.type != 'other'" class="cont">
              <span>环节名称：</span>
              <el-input
                v-model="item.stageName"
                class="dialog_input"
                placeholder="请输入"
                type="text"
              ></el-input>
              <span>可获得积分：</span>
              <el-input
                v-model="item.integral"
                class="dialog_input"
                placeholder="请输入"
                type="text"
              ></el-input>
              <span>是否可重复获得：</span>
              <el-radio-group
                v-model="item.repeatStatus"
                style="margin-right: 20px"
              >
                <el-radio
                  v-for="(item, i) in jfcfList"
                  :key="i"
                  :label="item.value"
                  size="large"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
              <I class="delete" name="RemoveFilled" @click="deleteJF(i)"></I>
            </div>
          </template>
        </div>
      </div>
      <div class="writeoff_box">
        <span>回收环节：</span>
        <el-radio-group
          v-model="addForm.recoveryType"
          style="margin-right: 20px"
          @change="recoveryChange"
        >
          <el-radio
            v-for="(item, i) in recoveryTypeList"
            :key="i"
            :label="item.value"
            size="large"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <div v-if="addForm.recoveryType == 1" class="writeoff_box jf_box">
        <span></span>
        <div>每盒可获得
          <el-input
            v-model="addForm.recoveryIntegral"
            placeholder="请输入"
            style="width: 120px"
            type="text"
            @keyup="numberInput"
          ></el-input>
          积分
        </div>
      </div>
      <div class="writeoff_box">
        <span><i>*</i>参与门槛：</span>
        <el-radio-group
          v-model="addForm.participationThreshold"
          style="margin-right: 20px"
        >
          <el-radio
            v-for="(item, i) in thresholdList"
            :key="i"
            :label="item.value"
            size="large"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
      <div
        v-if="addForm.participationThreshold == 3"
        class="writeoff_box jf_box"
      >
        <span></span>
        <div style="font-size: 14px">
          活动券价格：
          <el-input
            v-model="addForm.couponPrice"
            style="width: 100px"
          ></el-input
          >&#12288;元
        </div>
      </div>
    </div>
    <div v-if="themeTitle == '新建'" class="btns">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit"> 创建活动</el-button>
    </div>
    <div v-if="themeTitle == '修改'" class="btns">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit"> 修改活动</el-button>
    </div>
    <el-dialog
      v-model="userVisible"
      :before-close="getUserClose"
      title="选择用户"
      top="10vh"
      width="800px"
    >
      <div>
        <span>用户信息：</span>
        <el-input
          v-model="userInfo"
          clearable
          placeholder="请输入"
          style="width: 200px; margin-right: 10px"
        />
        <el-button type="primary" @click="search">查询</el-button>
      </div>
      <el-table
        v-if="userTableData.length > 0"
        ref="multipleTable"
        :data="userTableData"
        height="400"
        style="width: 100%"
      >
        <el-table-column label="选择" width="60">
          <template #header>
            <el-checkbox v-model="selectAll"></el-checkbox>
          </template>
          <template #default="{ row }">
            <div class="flex row-center" @click.stop>
              <el-checkbox
                :disabled="initdisabled(row)"
                :model-value="selectItem(row)"
                @change="handleSelect($event, row)"
              ></el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="用户头像">
          <template #default="scoped">
            <div class="desc">
              <img
                :src="
                  scoped.row.avatarUrl
                    ? scoped.row.avatarUrl
                    : require('../../../assets/img/logo.jpg')
                "
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="昵称" prop="nickName">
        </el-table-column>
        <el-table-column align="center" label="手机号" prop="phone">
        </el-table-column>
        <el-table-column align="center" label="注册时间" prop="createTime">
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="userPagination.total > 0"
        :current-page="userPagination.current"
        :page-size="userPagination.size"
        :page-sizes="[10, 20, 30, 40]"
        :total="userPagination.total"
        layout="total, sizes, prev, pager, next, jumper"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="getUserClose"> 取消 </el-button>
          <el-button type="primary" @click="onSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {queryList} from '@/api/activityManagement/activityTypeManagement'
import {computed, onMounted, reactive, ref, toRefs} from 'vue'
import {ElMessage} from 'element-plus'
import {putObject} from '@/api/eventManagement'
import {useRoute, useRouter} from 'vue-router'
import {guestPage, wechatUserList} from '@/api/userManagement/user'
import {activityAdd, activityEdit, activityEvery} from '@/api/themeConfiguration'
import JSONBig from 'json-bigint'
import conversionLong from '@/utils/conversionLong'

const router = useRouter()
const route = useRoute()
const quillRef = ref()
const quillRefAddress = ref()

const OSS = require('ali-oss')
const initData = reactive({
  addForm: {
    typeId: '',
    activityName: '',
    subtitle: '',
    invitationType: 0,
    description: '',
    picUrl: '',
    limitedQuota: '',
    limitedQuotaTotal: '',
    sort: '',
    participationThreshold: 1,
    couponPrice: '',
    recoveryType: 0,
    recoveryIntegral: ''
  },
  userVisible: false,
  userTableData: [],
  userPagination: {
    current: 1,
    size: 10,
    total: 0
  },
  parentList: [],
  dateTime: [],
  id: '',
  shelvesStatusList: [],
  // 文件
  fileList: [],
  fileObj: {},
  fullscreenLoading: false,
  ossObj: '',
  loadingDialog: false,
  fileDisabled: false,
  // 积分环节
  stageModelList: [],
  // 时间环节
  timeModelList: [],
  jfcfList: [
    {
      value: 0,
      label: '是'
    },
    {
      value: 1,
      label: '否'
    }
  ],
  themeTitle: '',
  editorOption: {
    modules: {
      // 工具栏的具体配置
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{header: 1}, {header: 2}],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{color: []}, {background: []}],
        ['clean']
      ]
    },
    placeholder: '请输入内容...'
  },
  addressOption: {
    placeholder: '请输入地址...'
  },
  summary: '',
  defaultValue: '',
  // 席位
  seats: [],
  seatsIndex: 0,
  thresholdList: [
    {
      value: 1,
      label: '无门槛'
    },
    {
      value: 2,
      label: '用券参与'
    },
    {
      value: 3,
      label: '用券或购买活动券'
    }
  ],
  recoveryTypeList: [
    {
      value: 0,
      label: '无'
    },
    {
      value: 1,
      label: '有'
    }
  ],
  invitationTypeList: [
    {
      value: 0,
      label: '公开'
    },
    {
      value: 1,
      label: '定向邀约'
    }
  ],
  // 活动地址
  addressModelList: [],
  userInfo: '',
  // 限制长度
  TiLength: 0
})
const {
  addForm,
  dateTime,
  id,
  fileList,
  fileObj,
  fullscreenLoading,
  ossObj,
  loadingDialog,
  fileDisabled,
  stageModelList,
  timeModelList,
  jfcfList,
  parentList,
  themeTitle,
  editorOption,
  addressOption,
  summary,
  seats,
  seatsIndex,
  userVisible,
  userTableData,
  userPagination,
  thresholdList,
  addressModelList,
  userInfo,
  invitationTypeList,
  recoveryTypeList,
  TiLength
} = toRefs(initData)

onMounted(() => {
  document.getElementsByClassName('app-main')[0].scrollTo(0, 0)
  quillRef.value.getQuill().enable(false)
  // quillRefAddress.value.getQuill().enable(false)
  setTimeout(() => {
    quillRef.value.getQuill().enable(true)
    // quillRefAddress.value.getQuill().enable(true)
  }, 1000)
  getselectList()
  if (route.query.id) {
    id.value = route.query.id
    getData()
  }
  if (route.query.name) {
    themeTitle.value = route.query.name
  }
})

// 添加活动席位
const addSeats = () => {
  if (seats.value.length == 0) {
    seats.value.push({
      seatName: '',
      seatsList: [],
      changeCurrentData: [],
      userId: []
    })
  } else {
    let flag = seats.value.some(item => {
      return item.seatName == '' || item.seatsList.length == 0
    })
    if (flag) {
      ElMessage.warning('请设置活动席位')
    } else {
      seats.value.push({
        seatName: '',
        seatsList: [],
        changeCurrentData: [],
        userId: []
      })
    }
  }
}

// 删除活动席位
const deleteSeats = index => {
  seats.value = seats.value.filter((item, i) => {
    return i != index
  })
}

const onEditorChange = (e) => {
  TiLength.value = quillRef.value.getText().length - 1
  if (TiLength.value > 5000) {
    quillRef.value.setText(quillRef.value.getText().slice(0, 5000))
    return
  }
}
// 选择用户
const getUser = index => {
  seatsIndex.value = index
  getUserDataList()
  userVisible.value = true
}

// 选择用户弹窗
const getUserClose = () => {
  userVisible.value = false
  const ids = seats.value[seatsIndex.value].seatsList.map(item => item.id)
  const cus = seats.value[seatsIndex.value].changeCurrentData.map(
    item => item.id
  )
  if (ids.length > cus.length) {
    let item = seats.value[seatsIndex.value].seatsList.filter(
      itemB => !cus.includes(itemB.id)
    )
    seats.value[seatsIndex.value].changeCurrentData.push(...item)
  } else {
    seats.value[seatsIndex.value].changeCurrentData = seats.value[seatsIndex.value].changeCurrentData.filter(itemB => ids.includes(itemB.id))
  }
}

const selectAll = computed({
  get: () => {
    if (!seats.value[seatsIndex.value].changeCurrentData) return false
    const ids = seats.value[seatsIndex.value].changeCurrentData.map(
      item => item.id
    )
    if (!userTableData.value.length) {
      return false
    }
    return userTableData.value.every(item => ids.includes(item.id))
  },
  set: val => {
    if (val) {
      for (let i = 0; i < userTableData.value.length; i++) {
        const item = userTableData.value[i]
        const ids = seats.value[seatsIndex.value].changeCurrentData.map(
          item => item.id
        )
        if (
          !ids.includes(item.id) &&
          seats.value[seatsIndex.value].changeCurrentData.length < 25
        ) {
          seats.value[seatsIndex.value].changeCurrentData.push(item)
        }
      }
    } else {
      userTableData.value.forEach(row => {
        deleteSelectedData(row)
      })
    }
  }
})

const selectItem = computed(() => {
  return row => {
    if (!seats.value[seatsIndex.value].changeCurrentData) return false
    return seats.value[seatsIndex.value].changeCurrentData.some(
      item => item.id == row.id
    )
  }
})

const initdisabled = row => {
  const seatsList = []
  seats.value.forEach((item, i) => {
    if (i != seatsIndex.value) {
      seatsList.push(...item.changeCurrentData)
    }
  })
  return seatsList.some(item => item.id == row.id)
}

const handleSelect = ($event, row) => {
  if ($event) {
    seats.value[seatsIndex.value].changeCurrentData.push(row)
  } else {
    deleteSelectedData(row)
  }
}

const deleteSelectedData = row => {
  const index = seats.value[seatsIndex.value].changeCurrentData.findIndex(
    item => item.id == row.id
  )
  if (index != -1) {
    seats.value[seatsIndex.value].changeCurrentData.splice(index, 1)
  }
}

// 弹窗确认
const onSubmit = () => {
  seats.value[seatsIndex.value].seatsList = []
  if (seats.value[seatsIndex.value].changeCurrentData.length == 0) {
    ElMessage.warning('请选择用户')
    return false
  }
  seats.value[seatsIndex.value].changeCurrentData.forEach(item => {
    seats.value[seatsIndex.value].seatsList.push(item)
  })
  userVisible.value = false
}

const search = () => {
  userPagination.value.current = 1
  userPagination.value.size = 10
  getUserDataList()
}

const handleSizeChange = val => {
  userPagination.value.current = 1
  userPagination.value.size = val
  getUserDataList()
}

const handleCurrentChange = val => {
  userPagination.value.current = val
  getUserDataList()
}

// 获取列表数据
const getUserDataList = () => {
  const data = {
    current: userPagination.value.current,
    size: userPagination.value.size
  }
  guestPage(data).then(({data: res}) => {
    if (res.code == 200) {
      userTableData.value = res.data.records
      userPagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      userTableData.value = []
      userPagination.value.total = 0
    }
  })
}

const disabledDate = time => {
  //此条为设置禁止用户选择今天之前的日期，包含今天。
  // return time.getTime() <= (Date.now());
  //此条为设置禁止用户选择今天之前的日期，不包含今天。
  return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
}

const disabledDate1 = time => {
  let start = Date.now(),
    end = Date.now()
  if (dateTime.value && dateTime.value.length) {
    start = dateTime.value[0]
    end = dateTime.value[1]
  }
  return (
    time.getTime() < new Date(start).getTime() - 24 * 60 * 60 * 1000 ||
    time.getTime() > new Date(end).getTime()
  )
}

// 计算总人数
const getChangeTime = val => {
  if (!val) {
    addressModelList.value = [
      {
        offlineAddress: '',
        reservationTimeModelList: []
      }
    ]
  }
  if (val && addForm.value.limitedQuota != '') {
    addForm.value.limitedQuotaTotal =
      Number(addForm.value.limitedQuota) * (DateDiff(val[0], val[1]) + 1)
  } else {
    addForm.value.limitedQuotaTotal = ''
  }
}

// 当前预约人数
const numberInput1 = () => {
  addForm.value.limitedQuota = addForm.value.limitedQuota.replace(/[^\d]/g, '')
  if (dateTime.value && dateTime.value.length > 0) {
    let num =
      Number(addForm.value.limitedQuota) *
      (DateDiff(dateTime.value[0], dateTime.value[1]) + 1)
    addForm.value.limitedQuotaTotal = num == 0 ? '' : num
  }
}

// 总预约人数
const numberInput2 = e => {
  addForm.value.limitedQuotaTotal = addForm.value.limitedQuotaTotal.replace(
    /[^\d]/g,
    ''
  )
}

const handleBlur = () => {
  if (
    dateTime.value &&
    dateTime.value.length > 0 &&
    addForm.value.limitedQuota != ''
  ) {
    let num =
      Number(addForm.value.limitedQuota) *
      (DateDiff(dateTime.value[0], dateTime.value[1]) + 1)
    if (addForm.value.limitedQuotaTotal < num) {
      addForm.value.limitedQuotaTotal = num
    }
  }
}

const DateDiff = (sDate1, sDate2) => {
  var oDate1, oDate2, iDays
  oDate1 = new Date(sDate1)
  oDate2 = new Date(sDate2)
  iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24)
  return iDays
}

// 只能输入数字
const numberInput = (e) => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}

// 添加时间环节
const addTime = () => {
  if (timeModelList.value.length == 0) {
    timeModelList.value.push({
      start: '',
      end: '',
      peopleCount: ''
    })
  } else {
    let flag = timeModelList.value.some(item => {
      return item.start == '' || item.end == '' || item.peopleCount == ''
    })
    if (flag) {
      ElMessage.warning('请填写预约时间段')
    } else {
      timeModelList.value.push({
        start: '',
        end: '',
        peopleCount: ''
      })
    }
  }
}
// 删除时间
const deleteTime = index => {
  timeModelList.value = timeModelList.value.filter((item, i) => {
    return i != index
  })
}

// 获取主题活动列表
// const getselectList = () => {
//   selectList({ type: 1 }).then(({ data: res }) => {
//     if (res.code == 200) {
//       const list = res.data.map((item) => {
//         return {
//           label: item.title,
//           value: item.id,
//         };
//       });
//       parentList.value = list;
//     } else {
//       ElMessage.error(res.msg);
//     }
//   });
// };

// 活动类型
const getselectList = () => {
  queryList().then(({data: res}) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.typeName,
          value: item.id
        }
      })
      parentList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 返回上一级、取消
const cancel = () => {
  router.push('/themeConfiguration')
}

// 创建活动
const submit = () => {
  if (addForm.value.typeId == '') {
    ElMessage.warning('请输入关联主题活动')
    return false
  }
  if (addForm.value.activityName == '') {
    ElMessage.warning('请输入活动标题')
    return false
  }
  if (addForm.value.subtitle == '') {
    ElMessage.warning('请输入副标题')
    return false
  }
  if (dateTime.value.length == 0) {
    ElMessage.warning('请选择活动时间')
    return false
  }
  if (addForm.value.limitedQuota == '') {
    ElMessage.warning('请输入每天可预约人数')
    return false
  }
  // if (!regExp(quillRefAddress.value.getText())) {
  //   ElMessage.warning('请输入活动地址')
  //   return false
  // }
  if (!fileObj.value.url) {
    ElMessage.warning('请上传活动封面图')
    return false
  }
  let addressFlag = addressModelList.value.some(item => {
    return item.offlineAddress == ''
  })
  if (addressFlag) {
    ElMessage.warning('请填写活动地址')
    return false
  }
  // let seatsFlag = seats.value.some(item => {
  //   return item.seatName == '' || (item.seatsList && item.seatsList.length == 0)
  // })
  // if (seatsFlag) {
  //   ElMessage.warning('请设置活动席位')
  //   return false
  // }
  // if (!stageModelList.value || stageModelList.value.length == 0) {
  //   ElMessage.warning('请填写积分环节信息')
  //   return false
  // }
  // let stageModelListFlag = stageModelList.value.some(item => {
  //   return item.stageName == '' || item.integral === ''
  // })
  // if (stageModelListFlag) {
  //   ElMessage.warning('请填写积分环节信息')
  //   return false
  // }
  // if (!timeModelList.value || timeModelList.value.length == 0) {
  //   ElMessage.warning('请填写预约时间段')
  //   return false
  // }
  let timeModelListFlag = timeModelList.value.some(item => {
    return item.start == '' || item.end == '' || item.peopleCount === ''
  })
  if (timeModelListFlag) {
    ElMessage.warning('请填写预约时间段')
    return false
  }
  if (addForm.value.sort == '') {
    ElMessage.warning('请输入排序')
    return false
  }
  if (
    addForm.value.participationThreshold == 3 &&
    addForm.value.couponPrice == ''
  ) {
    ElMessage.warning('请输入活动券价格')
    return false
  }
  if (addForm.value.recoveryType == 1) {
    if (addForm.value.recoveryIntegral == '') {
      ElMessage.warning('请输入回收环节积分')
      return false
    }
  }
  let data = addForm.value
  timeModelList.value.forEach(item => {
    item.peopleCount = Number(item.peopleCount)
  })
  addressModelList.value.forEach(item => {
    item.reservationTimeModelList.forEach(item1 => {
      item1.beginTime = item1.date[0]
      item1.endTime = item1.date[1]
    })
  })
  seats.value.forEach(item => {
    item.userId = item.seatsList.map(item1 => item1.id)
  })
  data.stageModelList = stageModelList.value
  data.timeModelList = timeModelList.value
  data.addressModelList = addressModelList.value
  data.seatModelList = seats.value
  data.beginTime = dateTime.value[0]
  data.endTime = dateTime.value[1]
  data.picUrl = fileObj.value.url
  if (summary.value) {
    data.description = summary.value
  }
  if (id.value == '') {
    activityAdd(data).then(({data: res}) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push('/themeConfiguration')
        }, 1000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.id = id.value
    data.stageModelList.forEach(item => {
      if (!item.stageName) {
        item.stageName = item.integral
      }
    })
    activityEdit(data).then(({data: res}) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push('/themeConfiguration')
        }, 1000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 富文本验证
const regExp = str => {
  const pattern_Ch = new RegExp('[\u4E00-\u9FA5]')
  const pattern_En = new RegExp('[A-Za-z]')
  const pattern_Num = new RegExp('[0-9]')
  const pattern_Sy = new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/\\?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-/]|[\\\\/]"
  )
  return (
    pattern_Ch.test(str) ||
    pattern_En.test(str) ||
    pattern_Sy.test(str) ||
    pattern_Num.test(str)
  )
}

const getData = () => {
  activityEvery({id: id.value}).then(({data: res}) => {
    if (res.code == 200) {
      addForm.value = res.data
      dateTime.value = [res.data.beginTime, res.data.endTime]
      fileObj.value.url = res.data.picUrl
      stageModelList.value = JSON.parse(res.data.metadata).stageList || []
      seats.value = conversionLong(JSONBig.parse(res.data.metadata)).seatList || []
      addressModelList.value = JSON.parse(res.data.metadata).addressList || []
      addressModelList.value.forEach(item => {
        item.reservationTimeModelList.forEach(item1 => {
          item1.date = [item1.beginTime, item1.endTime]
        })
      })
      timeModelList.value = JSON.parse(res.data.metadata).timeList || []
      summary.value = res.data.description
      quillRef.value.setHTML(res.data.description)
      // quillRefAddress.value.setHTML(res.data.offlineAddress)
      getCurrentData()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 循环获取席位数据
async function getCurrentData() {
  for (let i = 0; i < seats.value.length; i++) {
    try {
      const {data: res} = await wechatUserList({userIds: seats.value[i].userId});
      seats.value[i].seatsList = res.data
      seats.value[i].changeCurrentData = res.data
    } catch (error) {
      console.error(`请求 ${i} 失败:`, error);
    }
  }
}

// 点击添加活动地址
const addRessList = () => {
  if (addressModelList.value.length == 0) {
    addressModelList.value.push({
      offlineAddress: '',
      reservationTimeModelList: []
    })
  } else {
    let flag = addressModelList.value.some(item => {
      return item.offlineAddress == ''
    })
    if (flag) {
      ElMessage.warning('请填写活动地址')
    } else {
      addressModelList.value.push({
        offlineAddress: '',
        reservationTimeModelList: []
      })
    }
  }
}

// 删除活动地址
const deleteAddressModel = index => {
  addressModelList.value = addressModelList.value.filter((item, i) => {
    return i != index
  })
}

// 添加预约日期
const addDateList = index => {
  if (!dateTime.value || dateTime.value.length == 0) {
    ElMessage.warning('请选择活动时间')
    return false
  }
  if (addressModelList.value[index].reservationTimeModelList.length == 0) {
    addressModelList.value[index].reservationTimeModelList.push({
      beginTime: '',
      endTime: '',
      date: [],
      timeModelList: []
    })
  } else {
    let flag = addressModelList.value[index].reservationTimeModelList.some(
      item => {
        return item.date && item.date.length == 0
      }
    )
    if (flag) {
      ElMessage.warning('请填写预约日期')
    } else {
      addressModelList.value[index].reservationTimeModelList.push({
        beginTime: '',
        endTime: '',
        date: [],
        timeModelList: []
      })
    }
  }
}

// 删除预约日期
const deleteDateList = (pindex, index) => {
  addressModelList.value[pindex].reservationTimeModelList =
    addressModelList.value[pindex].reservationTimeModelList.filter(
      (item, i) => {
        return i != index
      }
    )
}

// 添加预约时间段
const addTimeList = (pindex, index) => {
  if (
    addressModelList.value[pindex].reservationTimeModelList[index].timeModelList
      .length == 0
  ) {
    addressModelList.value[pindex].reservationTimeModelList[
      index
      ].timeModelList.push({
      start: '',
      end: '',
      peopleCount: ''
    })
  } else {
    let flag = addressModelList.value[pindex].reservationTimeModelList[
      index
      ].timeModelList.some(item => {
      return item.start == '' || item.peopleCount == ''
    })
    if (flag) {
      ElMessage.warning('请填写预约时间')
    } else {
      addressModelList.value[pindex].reservationTimeModelList[
        index
        ].timeModelList.push({
        start: '',
        end: '',
        peopleCount: ''
      })
    }
  }
}

// 删除预约时间段
const deleteTimeList = (pindex, index, index2) => {
  addressModelList.value[pindex].reservationTimeModelList[index].timeModelList =
    addressModelList.value[pindex].reservationTimeModelList[
      index
      ].timeModelList.filter((item, i) => {
      return i != index2
    })
}

// 回收环节
const recoveryChange = () => {
  addForm.value.recoveryIntegral = ''
}

// 点击添加积分环节
const addJF = () => {
  if (stageModelList.value.length == 0) {
    stageModelList.value.push({
      stageName: '',
      integral: '',
      repeatStatus: 1
    })
  } else {
    let flag = stageModelList.value.some(item => {
      return item.stageName == '' || item.integral === ''
    })
    if (flag) {
      ElMessage.warning('请填写积分环节信息')
    } else {
      stageModelList.value.push({
        stageName: '',
        integral: '',
        repeatStatus: 1
      })
    }
  }
}

// 删除积分环节
const deleteJF = index => {
  stageModelList.value = stageModelList.value.filter((item, i) => {
    return i != index
  })
}

let client = null
// 文件超出个数限制时的钩子
const exceedFile = (files, fileList) => {
  ElMessage.warning(
    `只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`
  )
}

// 文件状态改变时的钩子
const fileChange = file => {
  fileList.value.push(file.raw)
}

const asyncImgChecked = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file) // 必须用file.raw
    reader.onload = () => {
      // 让页面中的img标签的src指向读取的路径
      let img = new Image()
      img.src = reader.result
      img.onload = () => {
        if (img.width != 850 || img.height != 676) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    }
  })
}

// 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
const beforeUploadFile = async file => {
  let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
  let size = file.size / 1024 / 1024
  if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
    ElMessage.warning('只能上传后缀是png、jpg、jpeg的文件')
    return false
  }
  if (size > 5) {
    ElMessage.error('上传图片大小不能超过 5MB!')
    return false
  }
  // let res = await asyncImgChecked(file)
  // if (!res) {
  //   ElMessage.warning('请上传850*676比例图片')
  //   return false
  // }
}

const handleRemove = () => {
  fileList.value = []
  fileObj.value.url = ''
}

const UploadUrl = () => {
  // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
  return ''
}

// 上传
const uploadFile = option => {
  // 获取文件的后缀名
  fullscreenLoading.value = true
  putObject({fileName: option.file.name}).then(({data: res}) => {
    if (res.code == 200) {
      ossObj.value = res.data
      client = new OSS({
        region: res.data.regionId,
        secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        bucket: res.data.bucketName,
        cname: true,
        endpoint: `https://${res.data.cdnDomain}`
      })
      put(`${res.data.dirPath + res.data.fileName}`, option.file).then(
        ({res: data}) => {
          fullscreenLoading.value = false
          if (data.status == 200) {
            fileObj.value.url = data.requestUrls[0]
          } else {
            ElMessage.warning('上传失败')
          }
        }
      )
    }
  })
}

/**
 * @param {string} ObjName OSS的储存路径和文件名字
 * @param {string} fileUrl 本地文件
 * @retruns Promise
 */

const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl)
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
    return result
  } catch (e) {
    console.log(e)
  }
}
</script>
<style lang="scss" scoped>
.addTheme {
  .top {
    display: flex;
    justify-content: space-between;
    margin: 15px 30px;

    h6 {
      font-size: 20px;
      font-weight: normal;
    }

    .back {
      font-size: 14px;
      color: #409eff;
      cursor: pointer;
    }
  }

  .writeoff_box {
    // display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;

    span {
      display: inline-block;
      width: 120px;
      text-align: right;
      position: relative;
      font-size: 14px;

      i {
        color: red;
        font-size: 20px;
      }
    }
  }

  .remark_box {
    display: flex;
    align-items: flex-start;
  }

  .block {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }

  .addTheme_main {
    margin-left: 50px;
    position: relative;

    .addTheme_main_mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }

  .addhj {
    color: #409eff;
    cursor: pointer;
  }

  .jf_box {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .dialog_input {
      width: 200px;
      margin-right: 20px;
    }

    .cont {
      display: flex;
      align-items: center;

      & > span {
        width: auto;
      }

      .delete {
        color: red;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .btns {
    text-align: center;
    margin: 30px 0;
  }

  .icon_box {
    display: flex;

    .tipIcon {
      cursor: pointer;
      margin-left: 15px;
      font-size: 22px !important;
    }
  }

  .demo-time-range {
    margin-right: 15px;
    width: 250px;

    :deep(.el-select) {
      width: 49%;
    }
  }
}
</style>
<style lang="scss">
.addTheme {
  .writeoff_box {
    .el-date-editor {
      width: 400px;
    }

    .dialog_input {
      width: 400px;
    }
  }

  .upload-demo {
    width: 400px;
  }

  .address {
    .ql-toolbar {
      display: none;
    }

    .myQuillEditor {
      width: 400px !important;
      height: 200px !important;
      border-top: 1px solid #dcdfe6 !important;
      border-radius: 10px;
    }
  }

  .details {
    width: 800px;

    .myQuillEditor {
      height: 300px !important;
      border-top: 1px solid #dcdfe6 !important;
    }
    .numtip {
      width: 100px;
      height: 30px;
      text-align: center;
      position: absolute;
      bottom: 1px;
      right: 17px;
      background: #fff;
      line-height: 30px;
    }
  }

  .ql-editor.ql-blank::before {
    color: #999;
    font-style: normal;
  }
}

.desc {
  img {
    width: 50px;
    height: 50px;
  }
}
</style>

import request from '../Axios'

// 活动列表
export const listNewActivityType = data => {
  return request({
    url: '/newActivityType',
    method: 'get',
    params: data
  })
}

// 活动列表
export const queryList = () => {
  return request({
    url: '/newActivityType/queryList',
    method: 'get'
  })
}

// 活动类型列表
export const queryEnum = () => {
  return request({
    url: '/newActivityType/queryEnum',
    method: 'get'
  })
}


// 创建活动
export const addNewActivityType = data => {
  return request({
    url: '/newActivityType',
    method: 'post',
    data
  })
}

// 修改活动
export const editNewActivityType = data => {
  return request({
    url: '/newActivityType',
    method: 'put',
    data
  })
}

// 删除活动
export const deleteNewActivityType = params => {
  return request({
    url: `/newActivityType/?id=${params.id}`,
    method: 'delete'
  })
}
